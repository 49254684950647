import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useParams } from 'react-router-dom'
import useNotifications from '../../hooks/useNotifications'
import CreateAlertReportModal from './CreateAlertReportModal'
import useScanRangeSelect from '../alerts/useScanRangeSelect'
import useCreateAlertReportMutation from './useCreateAlertReportMutation'
import useCreateAlertReportFormState from './useCreateAlertReportFormState'

const CreateAlertReportModalWithState = ({
    level,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { id } = useParams()
    const [selectedAudits, setSelectedAudits] = useState(
        level === 'AUDIT' ? [{ id }] : []
    )
    const [createReport] = useCreateAlertReportMutation({
        onCompleted,
        onError,
    })
    // eslint-disable-next-line no-unused-vars
    const scanRangeState = useScanRangeSelect()
    const { startDate, endDate, period } = scanRangeState
    const formState = useCreateAlertReportFormState(selectedAudits, startDate)
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateAlertReportModal
            formState={formState}
            level={level}
            scanRangeState={scanRangeState}
            onAddAudit={(audit) => {
                const isSelected = selectedAudits.some(
                    (selectedAudit) => selectedAudit.id === audit.id
                )
                if (isSelected) {
                    const newAudits = selectedAudits.filter(
                        (foundAudit) => foundAudit.id !== audit.id
                    )
                    setSelectedAudits(newAudits)
                } else {
                    const newAudits = [...selectedAudits, audit]
                    const uniqueAudits = [
                        ...new Map(
                            newAudits.map((item) => [item.id, item])
                        ).values(),
                    ]
                    setSelectedAudits(uniqueAudits)
                }
            }}
            onDismiss={onDismiss}
            onAddAllVisibleAudits={(audits) => {
                const uniqueAudits = [
                    ...new Map(audits.map((item) => [item.id, item])).values(),
                ]
                setSelectedAudits(uniqueAudits)
            }}
            onRemoveAudit={(auditId) => {
                const newAudits = selectedAudits.filter(
                    (audit) => audit.id !== auditId
                )
                setSelectedAudits(newAudits)
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = {
                            startDate,
                            endDate,
                            period,
                            auditIds: selectedAudits.map(
                                (selectedAudit) => selectedAudit.id
                            ),
                        }
                        const variables = { input }
                        await createReport({ variables })
                        dispatchSuccess(
                            <Trans>Created alert report succesfully</Trans>
                        )
                        onDismiss()
                    } catch (submitError) {
                        dispatchGraphqlError(submitError)
                    }
                }
            }}
            selectedAudits={selectedAudits}
        />
    )
}

export default CreateAlertReportModalWithState
