import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useTableState from '../../hooks/useTableState'
import useAppState from '../../hooks/useAppState'
import SearchInput from '../../utils/SearchInput'
import ScanRangeSelect from '../alerts/ScanRangeSelect'
import TableActionsContainer from '../../utils/TableActionsContainer'
import Button from '../../utils/Button'
import ClassifyCookiesModalWithState from './ClassifyCookiesModalWithState'
import UpdateCookiesModalWithState from './UpdateCookiesModalWithState'
import {
    IdentifiedCookiesAvailableFilters,
    IdentifiedCookiesActiveFilters,
} from './IdentifiedCookiesFilters'

const IdentifiedCookiesPageActions = ({
    activeFilters,
    onRemoveFilter,
    onAddFilter,
    onRemoveAllFilters,
    search,
    purposes,
    selectedAudit,
    selectedVendor,
    selectedPurpose,
    scanRangeState,
    onChangeAudit,
    onChangeVendor,
    onChangePurpose,
    onSearch,
    onClassifyCompleted,
    onUpdateCompleted,
}) => {
    const {
        isSelectingRows,
        startSelectingRows,
        stopSelectingRows,
        selectedRows,
    } = useTableState()
    const { currentUser } = useAppState()
    const [classifyModalIsOpen, setClassifyModalIsOpen] = useState(false)
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)

    return (
        <>
            <TableActionsContainer isSticky>
                {isSelectingRows && (
                    <div className="flex flex-row gap-2">
                        <Button
                            type="white"
                            size="sm"
                            onClick={() => setClassifyModalIsOpen(true)}
                        >
                            <Trans>Classify</Trans>
                        </Button>
                        <Button
                            type="white"
                            size="sm"
                            onClick={() => setUpdateModalIsOpen(true)}
                        >
                            <Trans>Update Classification</Trans>
                        </Button>
                        <Button
                            type="white"
                            size="sm"
                            onClick={() => stopSelectingRows()}
                        >
                            <Trans>Cancel</Trans>
                        </Button>
                    </div>
                )}
                {!isSelectingRows && (
                    <div className="flex w-full justify-between">
                        <div className="flex items-center gap-2">
                            {currentUser.isStaff && (
                                <Button
                                    type="white"
                                    size="sm"
                                    iconBeforeProps={{
                                        name: 'check-done-01',
                                        size: 'sm',
                                    }}
                                    onClick={() => startSelectingRows()}
                                />
                            )}

                            <SearchInput value={search} onChange={onSearch} />
                            <IdentifiedCookiesActiveFilters
                                activeFilters={activeFilters}
                                onRemoveFilter={onRemoveFilter}
                                auditFilter={selectedAudit}
                                onChangeAuditFilter={onChangeAudit}
                                vendorsFilter={selectedVendor}
                                onChangeVendorsFilter={onChangeVendor}
                                purposes={purposes}
                                purposeFilter={selectedPurpose}
                                onChangePurposeFilter={onChangePurpose}
                            />
                            <IdentifiedCookiesAvailableFilters
                                onAddFilter={onAddFilter}
                                activeFilters={activeFilters}
                                onRemoveAllFilters={onRemoveAllFilters}
                            />
                        </div>
                        <div>
                            <ScanRangeSelect
                                onChange={scanRangeState.onChangeDate}
                                period={scanRangeState.selectedPeriod}
                                startDate={scanRangeState.startDate}
                                endDate={scanRangeState.endDate}
                            />
                        </div>
                    </div>
                )}
            </TableActionsContainer>
            {classifyModalIsOpen && (
                <ClassifyCookiesModalWithState
                    scanCookies={selectedRows.map((row) => row.name)}
                    onDismiss={() => setClassifyModalIsOpen(false)}
                    onCompleted={onClassifyCompleted}
                />
            )}
            {updateModalIsOpen && (
                <UpdateCookiesModalWithState
                    scanCookies={selectedRows.map((row) => row.name)}
                    onDismiss={() => setUpdateModalIsOpen(false)}
                    onCompleted={onUpdateCompleted}
                />
            )}
        </>
    )
}

export default IdentifiedCookiesPageActions
