/* eslint-disable no-lonely-if */
import React from 'react'
import { Trans } from '@lingui/macro'
import AuditSearchSelect from '../../utils/AuditSearchSelect'
import VendorSearchSelect from '../../utils/VendorSearchSelect'
import TableFiltersMenu from '../../utils/TableFiltersMenu'
import TableFiltersMenuItem from '../../utils/TableFiltersMenuItem'
import TableFilterSelect from '../../utils/TableFilterSelect'
import TableFilterSelectOption from '../../utils/TableFilterSelectOption'

export const IdentifiedTrackersAvailableFilters = ({
    activeFilters,
    onAddFilter,
    onRemoveAllFilters,
}) => {
    return (
        <TableFiltersMenu
            onRemoveAll={onRemoveAllFilters}
            iconBefore="filter-lines"
            label=""
            autoClose
        >
            <TableFiltersMenuItem
                onAdd={onAddFilter}
                activeFilters={activeFilters}
                iconName="review"
                name="purpose"
            >
                <Trans>Purpose</Trans>
            </TableFiltersMenuItem>
            <TableFiltersMenuItem
                onAdd={onAddFilter}
                activeFilters={activeFilters}
                iconName="scan"
                name="audit"
            >
                <Trans>Audit</Trans>
            </TableFiltersMenuItem>
            <TableFiltersMenuItem
                onAdd={onAddFilter}
                activeFilters={activeFilters}
                iconName="building-07"
                name="vendor"
            >
                <Trans>Vendor</Trans>
            </TableFiltersMenuItem>
        </TableFiltersMenu>
    )
}

export function IdentifiedTrackersActiveFilters({
    activeFilters,
    onRemoveFilter,
    purposes,
    purposeFilter,
    onChangePurposeFilter,
    vendorsFilter,
    onChangeVendorsFilter,
    auditFilter,
    onChangeAuditFilter,
}) {
    let purposeLabel

    if (purposeFilter === null) {
        purposeLabel = <Trans>Choose Purpose</Trans>
    } else {
        if (purposeFilter === 'UNCLASSIFIED') {
            purposeLabel = <Trans>Unclassified</Trans>
        } else if (purposeFilter === 'ALL') {
            purposeLabel = <Trans>All Purposes</Trans>
        } else {
            purposeLabel = purposes?.find((purpose) => {
                return purpose.id === purposeFilter
            })?.name
        }
    }

    let vendorsLabel
    if (vendorsFilter === null) {
        vendorsLabel = <Trans>Choose vendors</Trans>
    } else {
        vendorsLabel = <Trans>{vendorsFilter.name}</Trans>
    }

    let auditLabel
    if (auditFilter === null) {
        auditLabel = <Trans>Choose audit</Trans>
    } else {
        auditLabel = <Trans>{auditFilter.name}</Trans>
    }

    return (
        <>
            <TableFilterSelect
                name="audit"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
                label={auditLabel}
                position="bottom-start"
                dropdownClassName="max-h-[450px]"
                asChild
            >
                <AuditSearchSelect
                    value={auditFilter}
                    onSelect={(id, audit) => {
                        onChangeAuditFilter(audit)
                    }}
                    isCheckbox={false}
                    onDeselect={() => {
                        onChangeAuditFilter('ALL')
                    }}
                    className="h-[400px]"
                />
            </TableFilterSelect>
            <TableFilterSelect
                name="vendor"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
                label={vendorsLabel}
                position="bottom-start"
                dropdownClassName="max-h-[450px]"
                asChild
            >
                <VendorSearchSelect
                    value={vendorsFilter}
                    onSelect={(id) => {
                        onChangeVendorsFilter(id)
                    }}
                    isCheckbox={false}
                    onDeselect={() => {
                        onChangeVendorsFilter('ALL')
                    }}
                    className="h-[400px]"
                />
            </TableFilterSelect>
            <TableFilterSelect
                name="purpose"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
                label={purposeLabel}
                position="bottom-start"
                dropdownClassName="max-h-[450px]"
                asChild
            >
                <TableFilterSelectOption
                    value="ALL"
                    onClick={() => onChangePurposeFilter('ALL')}
                >
                    <Trans>All purposes</Trans>
                </TableFilterSelectOption>
                <TableFilterSelectOption
                    value="UNCLASSIFIED"
                    onClick={() => onChangePurposeFilter('UNCLASSIFIED')}
                >
                    <Trans>Unclassified</Trans>
                </TableFilterSelectOption>
                {purposes.map(({ id, name }) => (
                    <TableFilterSelectOption
                        value={purposeFilter}
                        key={id}
                        onClick={() => onChangePurposeFilter(id)}
                    >
                        {name}
                    </TableFilterSelectOption>
                ))}
            </TableFilterSelect>
        </>
    )
}
