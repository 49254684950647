import React from 'react'
import { Trans } from '@lingui/macro'
import clsx from 'clsx'
import { subMonths, startOfMonth, format } from 'date-fns'
import Select from '../../utils/Select'
import Input from '../../utils/Input'
import * as constants from '../../../constants'
import FormInputErrors from '../../utils/FormInputErrors'

const {
    IDENTIFIED_PERIOD_LAST_DAY_OPTION: LAST_DAY,
    IDENTIFIED_PERIOD_LAST_7_DAYS_OPTION: LAST_7,
    IDENTIFIED_PERIOD_LAST_14_DAYS_OPTION: LAST_14,
    IDENTIFIED_PERIOD_LAST_30_DAYS_OPTION: LAST_30,
    IDENTIFIED_PERIOD_DAY_OPTION: DAY,
    IDENTIFIED_PERIOD_MONTH_OPTION: MONTH,
    IDENTIFIED_PERIOD_CUSTOM_OPTION: CUSTOM,
} = constants

const currentDate = new Date()
const previousMonth = subMonths(startOfMonth(currentDate), 1)
const last12Months = []
for (let i = 0; i < 12; i += 1) {
    const month = subMonths(previousMonth, i)
    last12Months.push(month)
}

export default function ScanRangeSelect({
    hasEmptyOption = false,
    errors,
    onChange,
    period,
    startDate,
    endDate,
    fullWidth = false,
    className,
}) {
    const handleChange = (newPeriod, newRange = [null]) => {
        const [newStartDate, newEndDate] = newRange
        onChange(newPeriod, newStartDate, newEndDate)
    }

    const hasError = errors && errors.length > 0

    return (
        <>
            <div className={clsx('flex w-fit items-center gap-4', className)}>
                {period === DAY && (
                    <Input
                        type="date"
                        className={clsx(
                            fullWidth ? 'w-full' : 'w-[150px]',
                            hasError && 'border border-red-500 text-red-500'
                        )}
                        value={startDate}
                        onChange={(e) => handleChange(DAY, [e.target.value])}
                    />
                )}
                {period === MONTH && (
                    <Select
                        hasError={hasError}
                        value={startDate}
                        onChange={(e) => handleChange(MONTH, [e.target.value])}
                        className={fullWidth && 'w-full'}
                    >
                        <option value="">
                            {startDate === null
                                ? 'Select date'
                                : 'Clear selection'}
                        </option>
                        {last12Months.map((month) => (
                            <option
                                value={format(month, 'yyyy-MM-dd')}
                                key={month}
                            >
                                {format(month, 'MMMM yyyy')}
                            </option>
                        ))}
                    </Select>
                )}
                {period === CUSTOM && (
                    <div
                        className={clsx(
                            'flex items-center gap-2',
                            fullWidth ? 'w-full' : 'w-[300px]'
                        )}
                    >
                        <Input
                            type="date"
                            className="flex-1"
                            value={startDate}
                            onChange={(e) =>
                                handleChange(CUSTOM, [e.target.value, endDate])
                            }
                        />
                        <Input
                            type="date"
                            className="flex-1"
                            value={endDate}
                            onChange={(e) =>
                                handleChange(CUSTOM, [
                                    startDate,
                                    e.target.value,
                                ])
                            }
                        />
                    </div>
                )}
                <Select
                    hasError={hasError}
                    className={clsx(
                        fullWidth ? 'w-full' : 'w-fit min-w-[175px]',
                        hasError && 'border border-red-500 text-red-500'
                    )}
                    value={period}
                    onChange={(e) => handleChange(e.target.value)}
                >
                    {hasEmptyOption && (
                        <option value="none">
                            {period === 'none'
                                ? 'Select date'
                                : 'Clear selection'}
                        </option>
                    )}
                    <option value={LAST_DAY}>
                        <Trans>Last day</Trans>
                    </option>
                    <option value={LAST_7}>
                        <Trans>Last 7 days</Trans>
                    </option>
                    <option value={LAST_14}>
                        <Trans>Last 14 days</Trans>
                    </option>
                    <option value={LAST_30}>
                        <Trans>Last 30 days</Trans>
                    </option>
                    <option value={DAY}>
                        <Trans>Specific day</Trans>
                    </option>
                    <option value={MONTH}>
                        <Trans>Specific Month</Trans>
                    </option>
                </Select>
            </div>
            <FormInputErrors errors={errors} />
        </>
    )
}
