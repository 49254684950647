import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import PurposesTags from '../../utils/PurposesTags'
import TableCellAvatar from '../../utils/TableCellAvatar'
import Tooltip from '../../utils/Tooltip'
import useDrawerStack from '../../hooks/useDrawerStack'
import CookieTableItemTableRowActionButtonWithState from './CookieTableItemTableRowActionButtonWithState'
import Tag from '../../utils/Tag'
import IdentifiedCookieDetailsDrawer from '../vendors-cookies/IdentifiedCookieDetailsDrawer'
import VendorTypeTags from '../../utils/VendorTypeTags'
import useScanRangeSelect from '../alerts/useScanRangeSelect'

const FRAGMENT = gql`
    fragment CookieTableItemPageTableRow on CookieTableItem {
        id
        name
        identifiedCookie {
            id
            audits {
                id
                name
            }
        }
        occurrence
        status
        cookie {
            id
            description
            purposes {
                id
                name
            }
            vendor {
                id
                name
                privacyPolicyUrl
                imageUrl
                types
            }
        }
    }
`

const CookieTableItemPageTableRow = ({
    auditIds,
    cookieTableItem,
    onUpdateCookieTableItemsCompleted,
}) => {
    const { open, currentDrawerProps } = useDrawerStack()
    const { id, name, cookie } = cookieTableItem
    const dateRange = 'LAST_30_DAYS'

    const { startDate, endDate } = useScanRangeSelect(dateRange)

    return (
        <TableRow
            data-testid="CookieTablesPageTableRow"
            isSelectable
            isActive={currentDrawerProps?.cookieId === id}
            selectionCriteria={(row) => row.id === id}
            selectionValue={cookieTableItem}
            onClick={() => {
                if (cookieTableItem.identifiedCookie !== null) {
                    open(IdentifiedCookieDetailsDrawer, {
                        identifiedCookieId: cookieTableItem.identifiedCookie.id,
                        auditIds,
                        startDate,
                        endDate,
                    })
                }
            }}
        >
            <TableCellAvatar
                columnId="name"
                size="sm"
                truncate
                avatarSrc={cookie?.vendor?.imageUrl}
                avatarIconName="building-07"
                title={name}
            />
            <TableCell hasText columnId="purposes" size="sm" truncate>
                <PurposesTags purposes={cookie?.purposes || []} />
            </TableCell>
            <TableCellAvatar
                columnId="audits"
                size="sm"
                truncate
                avatarSrc={cookie?.vendor?.imageUrl}
                avatarIconName="building-07"
                title={
                    cookie?.vendor?.name || (
                        <span className="text-slate-300">
                            <Trans>Unclassified</Trans>
                        </span>
                    )
                }
            />
            <TableCell columnId="vendorTypes" size="sm" truncate>
                <VendorTypeTags types={cookie?.vendor?.types || []} />
            </TableCell>
            <TableCell hasText columnId="description" size="sm" truncate>
                {cookie?.description && (
                    <Tooltip
                        className="block overflow-hidden text-ellipsis"
                        contentClassName="max-w-lg"
                        title={<Trans>Description</Trans>}
                        content={cookie?.description}
                    >
                        {cookie?.description}
                    </Tooltip>
                )}
            </TableCell>
            <TableCell columnId="occurrence" size="sm" truncate>
                <Tag>{cookieTableItem.occurrence}</Tag>
            </TableCell>
            <TableCell hasText columnId="privacyPolicyUrl" size="sm" truncate>
                {cookie?.vendor?.privacyPolicyUrl && (
                    <Tooltip
                        className="block overflow-hidden text-ellipsis"
                        contentClassName="max-w-lg"
                        title={<Trans>Link to privacy policy</Trans>}
                        content={cookie?.vendor?.privacyPolicyUrl}
                    >
                        {cookie?.vendor?.privacyPolicyUrl}
                    </Tooltip>
                )}
            </TableCell>
            <TableCell size="sm" className="text-right">
                <CookieTableItemTableRowActionButtonWithState
                    cookieTableItem={cookieTableItem}
                    onUpdateCookieTableItemsCompleted={
                        onUpdateCookieTableItemsCompleted
                    }
                />
            </TableCell>
        </TableRow>
    )
}

CookieTableItemPageTableRow.FRAGMENT = FRAGMENT

export default CookieTableItemPageTableRow
