import React from 'react'
import gql from 'graphql-tag'
import Tag from '../../utils/Tag'
import DateTime from '../../utils/DateTime'
import Duration from '../../utils/Duration'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import * as constants from '../../../constants'
import useDrawerStack from '../../hooks/useDrawerStack'
import AuditSessionsDetailsDrawer from './AuditSessionsDrawer'
import Avatar from '../../utils/Avatar'

const {
    SCAN_PAGE_STATUS_IN_PROGRESS: IN_PROGRESS,
    SCAN_PAGE_STATUS_FAILED: FAILED,
    SESSION_DEVICE_ICONS: ICONS,
    SESSION_DEVICE_NAMES: NAMES,
} = constants

const FRAGMENT = gql`
    fragment AuditSessionsPageTableRow on Session {
        id
        deviceType
        duration
        consentProfile {
            id
            name
        }
        createdAt
        startedAt
    }
`

const AuditSessionsPageTableRow = ({ session }) => {
    const { open } = useDrawerStack()
    const { id, deviceType, createdAt, duration, consentProfile, startedAt } =
        session
    return (
        <TableRow
            isSelectable
            data-testid="AuditSessionPageTableRow"
            selectionValue={session}
            selectionCriteria={(row) => row.id === id}
            onClick={() => {
                open(AuditSessionsDetailsDrawer, {
                    sessionId: id,
                })
            }}
        >
            <TableCell columnId="session" hasText className="flex items-center">
                <Avatar
                    iconName={ICONS[deviceType]}
                    className="flex-shrink-0"
                />
                <div className="mx-4">
                    <div className="text-sm font-medium leading-5 text-gray-900">
                        {NAMES[deviceType]}
                    </div>
                    <div className="text-sm leading-5 text-gray-500">
                        <DateTime>{startedAt}</DateTime>
                    </div>
                </div>
            </TableCell>
            <TableCell columnId="consentProfile" hasText>
                <Tag type="gray" className="mr-2">
                    {consentProfile.name}
                </Tag>
            </TableCell>
            <TableCell columnId="uploaded" hasText>
                <DateTime dateOnly>{createdAt}</DateTime>
            </TableCell>
            <TableCell columnId="duration" hasText>
                <Duration format="words">{duration}</Duration>
            </TableCell>
            <TableCell />
        </TableRow>
    )
}

AuditSessionsPageTableRow.FRAGMENT = FRAGMENT

export default AuditSessionsPageTableRow
