import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'audits',
        validate: isRequired,
        message: <Trans>Choose an audit for this report.</Trans>,
    },
    {
        path: 'timePeriod',
        validate: isRequired,
        message: <Trans>Select an time period first</Trans>,
    },
])

const useCreateAlertReportFormState = (selectedAudits, selectedTimePeriod) => {
    const formState = useFormState(
        {
            audits: selectedAudits,
            timePeriod: selectedTimePeriod,
        },
        {
            validation,
        }
    )
    return formState
}

export default useCreateAlertReportFormState
