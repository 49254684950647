import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import CreateVendorTableReportModal from './CreateVendorTableReportModal'
import useCreateVendorTableReportMutation from './useCreateVendorTableReportMutation'
import useCreateVendorTableReportFormState from './useCreateVendorTableReportFormState'

const CreateVendorTableReportModalWithState = ({
    onDismiss,
    onCompleted,
    onError,
}) => {
    const [selectedVendorTable, setSelectedVendorTable] = useState('')
    const [createVendorReport] = useCreateVendorTableReportMutation({
        onCompleted,
        onError,
    })
    const formState = useCreateVendorTableReportFormState(selectedVendorTable)
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateVendorTableReportModal
            formState={formState}
            setSelectedVendorTable={setSelectedVendorTable}
            selectedVendorTable={selectedVendorTable}
            onDismiss={onDismiss}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = {
                            vendorTableId: selectedVendorTable,
                        }
                        const variables = { input }
                        await createVendorReport({ variables })
                        onDismiss()
                        dispatchSuccess(
                            <Trans>Vendor table report created</Trans>
                        )
                    } catch (submitError) {
                        dispatchGraphqlError(submitError)
                    }
                }
            }}
        />
    )
}

export default CreateVendorTableReportModalWithState
