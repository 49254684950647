import React from 'react'
import { twMerge } from 'tailwind-merge'
import DropdownMenu from './DropdownMenu'
import Button from './Button'

export default function TableFilterSelect({
    activeFilters = [],
    dropdownClassName,
    name,
    onRemove,
    label,
    iconBeforeProps,
    children,
    ...props
}) {
    if (!activeFilters.includes(name)) {
        return null
    }
    return (
        <DropdownMenu
            position="bottom-end"
            dropdownClassName={twMerge(
                'max-h-[400px] overflow-y-auto',
                dropdownClassName
            )}
            content={({ close }) => children}
            {...props}
        >
            <div className="flex">
                <Button
                    className="text-nowrap"
                    grouped="left"
                    type="white"
                    size="sm"
                    iconBeforeProps={iconBeforeProps}
                    iconAfterProps={{
                        name: 'chevron-down',
                        size: 'sm',
                    }}
                >
                    {label}
                </Button>
                <Button
                    grouped="right"
                    iconBeforeProps={{
                        name: 'x',
                        size: 'sm',
                    }}
                    onClick={() => onRemove(name)}
                    type="white"
                    size="sm"
                />
            </div>
        </DropdownMenu>
    )
}
