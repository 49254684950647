import React, { useState } from 'react'
import useNotifications from '../../hooks/useNotifications'
import useScanRangeSelect from '../alerts/useScanRangeSelect'
import CreateIdentifiedVendorReportModal from './CreateIdentifiedVendorReportModal'
import useCreateIdentifiedVendorsReportMutation from './useCreateIdentifiedVendorsReportMutation'
import useCreateIdentifiedVendorsReportFormState from './useCreateIdentifiedVendorsReportFormState'

const CreateIdentifiedVendorsReportModalWithState = ({
    onDismiss,
    onCompleted,
    onError,
}) => {
    const [selectedAudits, setSelectedAudits] = useState([])
    const [createReport] = useCreateIdentifiedVendorsReportMutation({
        onCompleted,
        onError,
    })
    const scanRangeState = useScanRangeSelect()
    const { startDate } = scanRangeState
    const formState = useCreateIdentifiedVendorsReportFormState(
        selectedAudits,
        startDate
    )
    const { dispatchGraphqlError } = useNotifications()
    return (
        <CreateIdentifiedVendorReportModal
            scanRangeState={scanRangeState}
            selectedAudits={selectedAudits}
            formState={formState}
            onAddAudit={(audit) => {
                const newAudits = [...selectedAudits, audit]
                const uniqueAudits = [
                    ...new Map(
                        newAudits.map((item) => [item.id, item])
                    ).values(),
                ]
                setSelectedAudits(uniqueAudits)
            }}
            onAddAllVisibleAudits={(audits) => {
                const uniqueAudits = [
                    ...new Map(audits.map((item) => [item.id, item])).values(),
                ]
                setSelectedAudits(uniqueAudits)
            }}
            onRemoveAudit={(id) => {
                const newAudits = selectedAudits.filter(
                    (audit) => audit.id !== id
                )
                setSelectedAudits(newAudits)
            }}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { startDate, endDate, period } = scanRangeState
                        const input = {
                            period,
                            startDate,
                            endDate,
                            auditIds: selectedAudits.map(({ id }) => id),
                        }
                        const variables = { input }
                        await createReport({ variables })
                        onDismiss()
                    } catch (submitError) {
                        dispatchGraphqlError(submitError)
                    }
                }
            }}
        />
    )
}

export default CreateIdentifiedVendorsReportModalWithState
