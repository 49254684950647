import React from 'react'
import { Trans } from '@lingui/macro'
import PageContent from '../../utils/PageContent'
import PageHeader from '../../layout/PageHeader'
import IdentifiedTrackersPageTable from './IdentifiedTrackersPageTable'
import IdentifiedTrackersPageActions from './IdentifiedTrackersPageActions'
import PageContainer from '../../layout/PageContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import TableStateProvider from '../../providers/TableStateProvider'
import IdentifiedVendorsPageSidebar from '../vendors/IdentifiedVendorsPageSidebar'

export default function IdentifiedTrackersPage({
    activeFilters,
    onRemoveFilter,
    onAddFilter,
    onRemoveAllFilters,
    purposes,
    trackers,
    search,
    selectedAudit,
    selectedVendor,
    selectedPurpose,
    scanRangeState,
    isFetching,
    isFetchingMore,
    isSelecting,
    hasMoreRecords,
    onSearch,
    onChangeDate,
    onChangeAudit,
    onChangeVendor,
    onChangePurpose,
    onFetchMore,
    onClassifyTrackerCompleted,
    onUpdateTrackerCompleted,
    onDeleteTrackerCompleted,
    onSelectTracker,
    onStartSelectingTrackers,
    onStopSelectingTrackers,
    onSelectAllTrackers,
    selectedTrackers,
    onSort,
}) {
    return (
        <PageContainer data-testid="IdentifiedTrackersPage">
            <OrganizationHeader />
            <SidebarContainer>
                <IdentifiedVendorsPageSidebar />
                <PageContent size="full">
                    <PageHeader
                        title={<Trans>Trackers observations</Trans>}
                        description={
                            <Trans>
                                Below you can find all trackers we observed on
                                your domains.
                            </Trans>
                        }
                    />
                    <TableStateProvider>
                        <IdentifiedTrackersPageActions
                            activeFilters={activeFilters}
                            onRemoveFilter={onRemoveFilter}
                            onAddFilter={onAddFilter}
                            onRemoveAllFilters={onRemoveAllFilters}
                            purposes={purposes}
                            search={search}
                            scanRangeState={scanRangeState}
                            selectedAudit={selectedAudit}
                            selectedVendor={selectedVendor}
                            selectedPurpose={selectedPurpose}
                            onChangeAudit={onChangeAudit}
                            onChangeVendor={onChangeVendor}
                            onChangeDate={onChangeDate}
                            onSearch={onSearch}
                            onChangePurpose={onChangePurpose}
                            onClassifyTrackerCompleted={
                                onClassifyTrackerCompleted
                            }
                            onUpdateTrackerCompleted={onUpdateTrackerCompleted}
                        />
                        <IdentifiedTrackersPageTable
                            trackers={trackers}
                            isFetchingMore={isFetchingMore}
                            isFetching={isFetching}
                            hasMoreRecords={hasMoreRecords}
                            onFetchMore={onFetchMore}
                            startDate={scanRangeState.startDate}
                            endDate={scanRangeState.endDate}
                            onClassifyTrackerCompleted={
                                onClassifyTrackerCompleted
                            }
                            onUpdateTrackerCompleted={onUpdateTrackerCompleted}
                            onDeleteTrackerCompleted={onDeleteTrackerCompleted}
                            isSelecting={isSelecting}
                            onSelectTracker={onSelectTracker}
                            onStartSelectingTrackers={onStartSelectingTrackers}
                            onStopSelectingTrackers={onStopSelectingTrackers}
                            onSelectAllTrackers={onSelectAllTrackers}
                            selectedTrackers={selectedTrackers}
                            onSort={onSort}
                        />
                    </TableStateProvider>
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
