import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>Choose a name for this vendor table.</Trans>,
    },
    {
        path: 'audits',
        validate: isRequired,
        message: <Trans>You are required to select an audit</Trans>,
    },
])

const useCreateVendorTableFormState = (selectedAudits) => {
    const formState = useFormState(
        {
            name: '',
            audits: selectedAudits.map(({ id }) => id),
        },
        {
            validation,
        }
    )
    return formState
}

export default useCreateVendorTableFormState
