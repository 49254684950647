import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import CreateCookieTableReportModal from './CreateCookieTableReportModal'
import useCreateCookieTableReportMutation from './useCreateCookieTableReportMutation'
import useCreateCookieTableReportFormState from './useCreateCookieTableReportFormState'

const CreateCookieTableReportModalWithState = ({
    onDismiss,
    onCompleted,
    onError,
}) => {
    const [selectedCookieTable, setSelectedCookieTable] = useState('')
    const [createCookieReport] = useCreateCookieTableReportMutation({
        onCompleted,
        onError,
    })
    const formState = useCreateCookieTableReportFormState(selectedCookieTable)
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateCookieTableReportModal
            formState={formState}
            setSelectedCookieTable={setSelectedCookieTable}
            selectedCookieTable={selectedCookieTable}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = {
                            cookieTableId: selectedCookieTable,
                        }
                        const variables = { input }
                        await createCookieReport({ variables })
                        onDismiss()
                        dispatchSuccess(
                            <Trans>Cookie table report created</Trans>
                        )
                    } catch (submitError) {
                        dispatchGraphqlError(submitError)
                    }
                }
            }}
        />
    )
}

export default CreateCookieTableReportModalWithState
