import React, { useRef, useState, useEffect, forwardRef } from 'react'
import clsx from 'clsx'

export const AnimatedTab = forwardRef(
    ({ isActive, children, name, className, ...props }, ref) => (
        <button
            ref={ref}
            type="button"
            aria-current={isActive ? 'page' : undefined}
            className={clsx(
                'my-auto cursor-pointer select-none rounded-full px-4 text-center text-sm text-gray-500',
                isActive ? 'text-gray-700' : 'hover:text-gray-700',
                className
            )}
            name={name}
            {...props}
        >
            {children}
        </button>
    )
)

const AnimatedTabs = ({ className, children, activeTab }) => {
    const tabsRef = useRef({})
    const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0)
    const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0)
    useEffect(() => {
        const currentTab = tabsRef.current[activeTab]
        if (currentTab) {
            setTabUnderlineLeft(currentTab.offsetLeft)
            setTabUnderlineWidth(currentTab.clientWidth)
        }
    }, [activeTab])

    const childrenWithProps = React.Children.map(children, (child, index) => {
        const { name } = child.props
        return React.cloneElement(child, {
            ref: (el) => {
                tabsRef.current[name] = el
            },
            isActive: name === activeTab,
        })
    })

    return (
        <div
            className={clsx(
                'relative flex w-fit rounded-lg border border-gray-200 bg-gray-50 px-0.5 py-1.5 text-sm font-semibold text-gray-500 backdrop-blur-sm',
                className
            )}
        >
            <span
                className="absolute bottom-0 top-0 -z-10 flex overflow-hidden rounded-md py-0.5 drop-shadow transition-all duration-300"
                style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
            >
                <span className="h-full w-full rounded-md bg-white" />
            </span>
            {childrenWithProps}
        </div>
    )
}

export default AnimatedTabs
