import React from 'react'
import { Trans } from '@lingui/macro'
import useTableState from '../../hooks/useTableState'
import useAppState from '../../hooks/useAppState'
import ScanRangeSelect from '../alerts/ScanRangeSelect'
import SearchInput from '../../utils/SearchInput'
import DropdownMenu from '../../utils/DropdownMenu'
import AuditSearchSelect from '../../utils/AuditSearchSelect'
import TableActionsContainer from '../../utils/TableActionsContainer'
import Button from '../../utils/Button'

const IdentifiedVendorsPageActions = ({
    search,
    selectedAudit,
    scanRangeState,
    onChangeAudit,
    onSearch,
}) => {
    const { isSelectingRows, stopSelectingRows } = useTableState()
    const { currentUser } = useAppState()

    return (
        <TableActionsContainer isSticky>
            {isSelectingRows && (
                <div className="flex flex-row gap-2">
                    <Button
                        type="white"
                        size="sm"
                        onClick={() => stopSelectingRows()}
                    >
                        <Trans>Cancel</Trans>
                    </Button>
                </div>
            )}
            {!isSelectingRows && (
                <div className="flex w-full justify-between">
                    <div className="flex items-center gap-2">
                        {currentUser.isStaff && (
                            <Button
                                type="white"
                                size="sm"
                                iconBeforeProps={{
                                    name: 'check-done-01',
                                    size: 'sm',
                                }}
                                disabled
                            />
                        )}
                        <SearchInput value={search} onChange={onSearch} />
                        <DropdownMenu
                            position="bottom-start"
                            // eslint-disable-next-line react/no-unstable-nested-components
                            content={({ close }) => (
                                <AuditSearchSelect
                                    value={selectedAudit}
                                    onSelect={(id, audit) => {
                                        onChangeAudit(audit)
                                        close()
                                    }}
                                    isCheckbox={false}
                                    onDeselect={() => {
                                        onChangeAudit('ALL')
                                        close()
                                    }}
                                    className="h-[400px]"
                                />
                            )}
                        >
                            <Button
                                className="text-nowrap"
                                type="white"
                                size="sm"
                                iconAfterProps={{
                                    name: 'chevron-down',
                                    size: 'sm',
                                }}
                            >
                                {selectedAudit.name || 'Select Audit'}
                            </Button>
                        </DropdownMenu>
                    </div>
                    <div>
                        <ScanRangeSelect
                            onChange={scanRangeState.onChangeDate}
                            period={scanRangeState.selectedPeriod}
                            startDate={scanRangeState.startDate}
                            endDate={scanRangeState.endDate}
                        />
                    </div>
                </div>
            )}
        </TableActionsContainer>
    )
}

export default IdentifiedVendorsPageActions
