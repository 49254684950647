import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'vendortable',
        validate: isRequired,
        message: <Trans>Choose a Vendortable for this report.</Trans>,
    },
])

const useCreateVendorTableReportFormState = (selectedVendorTable) => {
    const formState = useFormState(
        {
            vendortable: selectedVendorTable,
        },
        {
            validation,
        }
    )
    return formState
}

export default useCreateVendorTableReportFormState
