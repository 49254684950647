import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import CreateCookieTableModal from './CreateCookieTableModal'
import useCreateCookieTableMutation from './useCreateCookieTableMutation'
import useCreateCookieTableFormState from './useCreateCookieTableFormState'

const CreateCookieTableModalWithState = ({
    onDismiss,
    onCompleted,
    onError,
    audits = [],
}) => {
    const [selectedAudits, setSelectedAudits] = useState(audits)
    const [createCookieTable, { loading }] = useCreateCookieTableMutation({
        onCompleted,
        onError,
    })
    const formState = useCreateCookieTableFormState(selectedAudits)
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateCookieTableModal
            loading={loading}
            selectedAudits={selectedAudits}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onAddAudit={(audit) => {
                const newAudits = [...selectedAudits, audit]
                const uniqueAudits = [
                    ...new Map(
                        newAudits.map((item) => [item.id, item])
                    ).values(),
                ]
                setSelectedAudits(uniqueAudits)
            }}
            onRemoveAudit={(id) => {
                const newAudits = selectedAudits.filter(
                    (cookie) => cookie.id !== id
                )
                setSelectedAudits(newAudits)
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { name } = formState.values
                        const input = {
                            name,
                            audits: selectedAudits.map(({ id }) => id),
                        }
                        await createCookieTable({ variables: { input } })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: <Trans>New cookie table created!</Trans>,
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default CreateCookieTableModalWithState
