/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import ModalLoading from '../../utils/ModalLoading'
import FormGroup from '../../utils/FormGroup'
import FormSelectList from '../../utils/FormSelectList'
import AuditSearchSelect from '../../utils/AuditSearchSelect'
import ScanRangeSelect from '../alerts/ScanRangeSelect'
import ModalFooter from '../../utils/ModalFooter'

const CreateIdentifiedCookiesReportModal = ({
    scanRangeState,
    formState,
    isFetching = false,
    selectedAudits,
    onAddAudit,
    onRemoveAudit,
    onDismiss,
    onSubmit,
}) => {
    const { getErrorMessages } = formState
    return (
        <Modal className="max-w-2xl" onDismiss={onDismiss}>
            <ModalTitle
                title={<Trans>Create cookie observations report</Trans>}
                description={
                    <Trans>
                        Export all unique cookies across the selected audits and
                        timeframe
                    </Trans>
                }
                onClose={onDismiss}
            />
            {(() => {
                if (isFetching) {
                    return <ModalLoading />
                }
                return (
                    <>
                        <FormGroup
                            label={<Trans>Select time period</Trans>}
                            isHorizontal
                        >
                            <ScanRangeSelect
                                fullWidth
                                errors={getErrorMessages('timePeriod')}
                                onChange={scanRangeState.onChangeDate}
                                period={scanRangeState.selectedPeriod}
                                startDate={scanRangeState.startDate}
                                endDate={scanRangeState.endDate}
                            />
                        </FormGroup>
                        <FormGroup
                            label={<Trans>Selected audits</Trans>}
                            isHorizontal
                        >
                            <FormSelectList errors={getErrorMessages('audits')}>
                                <AuditSearchSelect
                                    className="h-[400px]"
                                    value={selectedAudits.map(({ id }) => id)}
                                    onSelect={(id, audit) => onAddAudit(audit)}
                                    onDeselect={(id) => onRemoveAudit(id)}
                                    selectedAudits={selectedAudits}
                                />
                            </FormSelectList>
                        </FormGroup>
                        <ModalFooter>
                            <Button type="white" onClick={onDismiss}>
                                <Trans>Cancel</Trans>
                            </Button>
                            <Button onClick={onSubmit}>
                                <Trans>Done</Trans>
                            </Button>
                        </ModalFooter>
                    </>
                )
            })()}
        </Modal>
    )
}

export default CreateIdentifiedCookiesReportModal
