import gql from 'graphql-tag'
import { useQuery } from '@apollo/client/index'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'

const SESSION_COOKIES_QUERY = gql`
    query sessionDetailCookiesDrawer(
        $sessionId: ID
        $search: String
        $offset: Int
        $filters: ScanCookiesFilters
    ) {
        scanCookies(
            sessionId: $sessionId
            search: $search
            offset: $offset
            filters: $filters
        ) {
            edges {
                cursor
                node {
                    id
                    type
                    rootDomain
                    name
                    value
                    retention
                    tracker {
                        id
                        domain
                        purposes {
                            id
                            name
                        }
                        vendor {
                            id
                            name
                            imageUrl
                        }
                    }
                    cookie {
                        id
                        purposes {
                            id
                            name
                        }
                        vendor {
                            id
                            name
                            imageUrl
                        }
                    }
                    alerts {
                        id
                        type
                        severityType
                        message
                        scan {
                            id
                        }
                    }
                }
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

export function useSessionDetailCookiesDrawerQuery({
    sessionId,
    search,
    filters = {},
}) {
    const variables = { sessionId, search, filters }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
        fetchMore,
    } = useQuery(SESSION_COOKIES_QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'scanCookies'
    )

    return {
        error,
        cookies: connectionToCollection(data.scanCookies),
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}
