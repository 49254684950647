import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import HeaderContainer from './HeaderContainer'
import HeaderProfileWithState from './HeaderProfileWithState'
import HeaderLogo from './HeaderLogo'
import useAppState from '../hooks/useAppState'
import AuditHeaderSwitcher from './AuditHeaderSwitcher'
import Avatar from '../utils/Avatar'
import Tooltip from '../utils/Tooltip'
import useAuditHeaderQuery from '../pages/audits-details/useAuditHeaderQuery'
import AuditHeaderMenu from './AuditHeaderMenu'

export default function AuditHeader() {
    const { audit, isFetching } = useAuditHeaderQuery()
    const { currentUser } = useAppState()
    const { currentOrganization = {} } = currentUser
    return (
        <>
            <HeaderContainer>
                <div className="flex w-96 items-center">
                    <HeaderLogo />
                    <div className="mx-4 h-4 border-l border-slate-300" />
                    <Tooltip
                        asChild
                        content={<Trans>Back to your organization</Trans>}
                        position="right"
                    >
                        <Link
                            to="/audits"
                            className="flex items-center hover:cursor-pointer"
                        >
                            <div className="group relative flex items-center">
                                <Avatar
                                    src={currentOrganization.imageUrl}
                                    size="sm"
                                />
                                <div className="absolute inset-0 hidden group-hover:block">
                                    <Avatar
                                        iconName="arrow-left"
                                        size="sm"
                                        className=""
                                    />
                                </div>
                            </div>
                        </Link>
                    </Tooltip>
                    <div className="mx-4 h-4 border-l border-slate-300" />
                    <AuditHeaderSwitcher currentAudit={audit} />
                </div>
                <AuditHeaderMenu
                    className="hidden lg:flex"
                    audit={audit}
                    isFetching={isFetching}
                />
                <div className="flex w-96 justify-end">
                    <HeaderProfileWithState />
                </div>
            </HeaderContainer>
            <HeaderContainer className="lg:hidden">
                <div />
                <AuditHeaderMenu audit={audit} isFetching={isFetching} />
                <div />
            </HeaderContainer>
        </>
    )
}
