import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import ModalLoading from '../../utils/ModalLoading'
import FormButtonGroup from '../../utils/FormButtonGroup'
import FormGroup from '../../utils/FormGroup'
import CookieTableSearchSelect from '../../utils/CookieTableSearchSelect'
import FormSelectList from '../../utils/FormSelectList'
import ModalFooter from '../../utils/ModalFooter'

const CreateCookieTableReportModal = ({
    formState,
    isFetching = false,
    setSelectedCookieTable,
    selectedCookieTable,
    onDismiss,
    onSubmit,
}) => {
    const { getErrorMessages } = formState
    return (
        <Modal className="max-w-2xl" onDismiss={onDismiss}>
            <ModalTitle
                title={<Trans>Create cookie table report</Trans>}
                description={
                    <Trans>
                        Export all cookies included in the selected cookie table
                    </Trans>
                }
                onClose={onDismiss}
                newModalTitle
            />
            {(() => {
                if (isFetching) {
                    return <ModalLoading />
                }
                return (
                    <>
                        <FormGroup
                            isHorizontal
                            label={<Trans>Select Cookietable</Trans>}
                        >
                            <FormSelectList
                                errors={getErrorMessages('cookietable')}
                            >
                                <CookieTableSearchSelect
                                    className="h-[400px]"
                                    onHandleSelectCookieTable={
                                        setSelectedCookieTable
                                    }
                                    selectedCookieTable={selectedCookieTable}
                                />
                            </FormSelectList>
                        </FormGroup>

                        <ModalFooter>
                            <Button type="white" onClick={onDismiss}>
                                <Trans>Cancel</Trans>
                            </Button>
                            <Button onClick={onSubmit}>
                                <Trans>Create</Trans>
                            </Button>
                        </ModalFooter>
                    </>
                )
            })()}
        </Modal>
    )
}

export default CreateCookieTableReportModal
