/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { twMerge } from 'tailwind-merge'
import useSearch from '../hooks/useSearch'
import InfiniteScroll from './InfiniteScroll'
import SelectList from './SelectList'
import SearchInput from './SearchInput'
import useAppState from '../hooks/useAppState'
import SelectListOption from './SelectListOption'
import FormInputErrors from './FormInputErrors'
import CreateVendorModalWithState from './CreateVendorModalWithState'
import CreateOrganizationVendorModalWithState from '../pages/classifications-organization-vendors/CreateOrganizationVendorModalWithState'
import Button from './Button'
import Tag from './Tag'
import useVendorSearchSelectQuery from './useVendorSearchSelectQuery'
import Avatar from './Avatar'

const VendorSearchSelect = ({
    className,
    isOrganizationContext = false,
    value = null,
    errors = [],
    initialVendor = null,
    doNotSelectVendor = false,
    onChange,
    onSelect,
    showSelectedVendor = true,
    canCreateVendor,
    isRadio = true,
    isCheckbox = false,
}) => {
    const { currentUser } = useAppState()
    const [vendorModalIsOpen, setVendorModalOpen] = useState(false)
    const [search, handleSearch] = useSearch('')
    const [selectedVendor, setSelectedVendor] = useState(null)
    const { vendors, isFetching, isFetchingMore, handleFetchMore, hasMore } =
        useVendorSearchSelectQuery({ search })

    const initialVendorIsSelected = initialVendor && initialVendor.id === value
    let finalInputValue = selectedVendor
    if (initialVendorIsSelected) {
        finalInputValue = initialVendor
    }

    const handleVendorSelect = (vendor) => {
        setSelectedVendor(vendor)
        if (onSelect) {
            onSelect(vendor)
        } else {
            onChange(vendor.id, vendor)
        }
    }

    return (
        <>
            <SelectList
                className={twMerge(
                    'h-64 overflow-hidden rounded-lg border',
                    className
                )}
                isFetching={isFetching}
                searchInput={
                    <div className="flex flex-col">
                        {finalInputValue && showSelectedVendor ? (
                            <div className="flex flex-row items-center gap-2 px-1 pb-2">
                                <Avatar
                                    src={finalInputValue.imageUrl}
                                    size="sm"
                                />
                                <p>
                                    <span className="font-bold">
                                        {finalInputValue.name}
                                    </span>{' '}
                                    <Trans>selected</Trans>
                                </p>
                            </div>
                        ) : (
                            <div className="border-gray-200 px-1 pb-2">
                                <Trans>No vendor selected</Trans>
                            </div>
                        )}
                        <div className="flex gap-2">
                            <SearchInput
                                value={search}
                                onChange={handleSearch}
                                className="w-full"
                            />
                            {canCreateVendor && (
                                <Button
                                    className="ml-2"
                                    size="sm"
                                    iconBeforeProps={{
                                        name: 'plus',
                                        color: 'white',
                                    }}
                                    onClick={() => setVendorModalOpen(true)}
                                >
                                    <Trans>Vendor</Trans>
                                </Button>
                            )}
                        </div>
                    </div>
                }
            >
                <InfiniteScroll
                    isFetchingMore={isFetchingMore}
                    onFetchMore={handleFetchMore}
                    hasMoreRecords={hasMore}
                >
                    {vendors?.map((vendor) => (
                        <SelectListOption
                            avatarSrc={vendor.imageUrl}
                            avatarDefaultIcon="building-07"
                            key={vendor.id}
                            disabled={false}
                            isCheckbox={isCheckbox}
                            isRadio={isRadio}
                            checked={
                                selectedVendor
                                    ? selectedVendor.id === vendor.id
                                    : Array.isArray(value)
                                      ? value.includes(vendor.id)
                                      : value === vendor.id
                            }
                            onClick={() => {
                                if (!doNotSelectVendor) {
                                    handleVendorSelect(vendor)
                                } else {
                                    onChange(vendor.id, vendor)
                                }
                            }}
                        >
                            {vendor.name}
                            {vendor.belongsToOrganization && (
                                <Tag className="ml-4">Your vendor</Tag>
                            )}
                        </SelectListOption>
                    ))}
                </InfiniteScroll>
            </SelectList>
            <FormInputErrors errors={errors} />
            {vendorModalIsOpen && (
                <>
                    {!currentUser.isStaff || isOrganizationContext ? (
                        <CreateOrganizationVendorModalWithState
                            onDismiss={() => setVendorModalOpen(false)}
                            onCompleted={({
                                createOrganizationVendor: { vendor },
                            }) => {
                                handleVendorSelect(vendor)
                            }}
                        />
                    ) : (
                        <CreateVendorModalWithState
                            onDismiss={() => setVendorModalOpen(false)}
                            onCompleted={({ createVendor: { vendor } }) => {
                                handleVendorSelect(vendor)
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}

export default VendorSearchSelect
