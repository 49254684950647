import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useDrawerStack from '../../hooks/useDrawerStack'
import useSearch from '../../hooks/useSearch'
import DrawerTabError from '../../utils/DrawerTabError'
import AnimatedTabs, { AnimatedTab } from '../../utils/AnimatedTabs'
import Tag from '../../utils/Tag'
import SearchInput from '../../utils/SearchInput'
import DrawerTabLoading from '../../utils/DrawerTabLoading'
import InfiniteScroll from '../../utils/InfiniteScroll'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import ScanRequestDetailsDrawer from '../scans/ScanRequestDetailsDrawer'
import TableRow from '../../utils/TableRow'
import TableCellAvatar from '../../utils/TableCellAvatar'
import Tooltip from '../../utils/Tooltip'
import TableCell from '../../utils/TableCell'
import AlertCountTag from '../../utils/AlertCountTag'
import PurposesTags from '../../utils/PurposesTags'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import { getRootDomain } from '../../../utils/index'
import { useSessionDetailRequestsDrawerQuery } from './useSessionDetailRequestsDrawerQuery'

export default function AuditSessionDetailsDrawerTabRequests({
    sessionId,
    audit,
    pageUrl = '',
}) {
    const { add } = useDrawerStack()
    const [search, handleSearch] = useSearch('')
    const [activeTab, setActiveTab] = useState('all')
    const {
        requests,
        isFetching: loading,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        error,
    } = useSessionDetailRequestsDrawerQuery({
        sessionId,
        search,
    })

    const requestsWithAlerts = requests.filter(
        (request) => request.alerts.length > 0
    )
    const filteredRequests = activeTab === 'all' ? requests : requestsWithAlerts
    if (error) return <DrawerTabError error={error} />
    return (
        <div className="flex flex-col gap-3">
            <div className="flex items-center gap-2">
                <AnimatedTabs activeTab={activeTab}>
                    <AnimatedTab
                        name="all"
                        className="px-3"
                        onClick={() => setActiveTab('all')}
                    >
                        <Trans>All</Trans>
                    </AnimatedTab>
                    <AnimatedTab
                        name="new"
                        className="px-3"
                        onClick={() => setActiveTab('new')}
                    >
                        <div className="flex flex-row items-center gap-1">
                            <Trans>Alerts</Trans>
                            <Tag>{requestsWithAlerts.length}</Tag>
                        </div>
                    </AnimatedTab>
                </AnimatedTabs>
                <SearchInput
                    id="sessionRequestSearch"
                    value={search}
                    onChange={handleSearch}
                    className="w-full"
                />
            </div>
            {loading && <DrawerTabLoading />}
            {!loading && (
                <InfiniteScroll
                    isFetchingMore={isFetchingMore}
                    onFetchMore={handleFetchMore}
                    hasMoreRecords={hasMore}
                >
                    <Table hasMinWidth={false} isRounded hasHeader={false}>
                        <TableBody>
                            {filteredRequests.length > 0 ? (
                                filteredRequests.map((request) => {
                                    const {
                                        id,
                                        url,
                                        method,
                                        resourceType,
                                        alerts,
                                        tracker,
                                    } = request
                                    const { hostname, pathname } = new URL(url)
                                    let tagPurposes = []
                                    if (tracker) {
                                        tagPurposes = tracker.purposes
                                    }
                                    let finalAvatarSrc =
                                        tracker?.vendor?.imageUrl
                                    if (
                                        getRootDomain(pageUrl) ===
                                        getRootDomain(url)
                                    ) {
                                        finalAvatarSrc = audit.imageUrl
                                    }
                                    return (
                                        <TableRow
                                            isSelectable
                                            key={id}
                                            onClick={() => {
                                                add(ScanRequestDetailsDrawer, {
                                                    requestId: request.id,
                                                })
                                            }}
                                        >
                                            <TableCellAvatar
                                                py="sm"
                                                px="sm"
                                                truncate
                                                avatarIconName="code-browser"
                                                avatarSrc={finalAvatarSrc}
                                                className="w-2/3"
                                                title={hostname}
                                                text={
                                                    <Tooltip
                                                        content={url}
                                                        contentClassName="max-w-[400px]"
                                                    >
                                                        {pathname}
                                                    </Tooltip>
                                                }
                                            />
                                            <TableCell py="sm">
                                                <div className="flex justify-end gap-1">
                                                    <AlertCountTag
                                                        alerts={alerts}
                                                    />
                                                    <PurposesTags
                                                        showAll
                                                        purposes={tagPurposes}
                                                    />
                                                    {resourceType && (
                                                        <Tag type="gray">
                                                            {resourceType}
                                                        </Tag>
                                                    )}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            ) : (
                                <DrawerEmptyCard
                                    iconName="tracker"
                                    title={<Trans>No requests</Trans>}
                                    description={
                                        <Trans>No requests were found</Trans>
                                    }
                                />
                            )}
                        </TableBody>
                    </Table>
                </InfiniteScroll>
            )}
        </div>
    )
}
