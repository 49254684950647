import React, { useState } from 'react'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import useRefetch from '../../hooks/useRefetch'
import useEvents from '../../hooks/useEvents'
import useSearch from '../../hooks/useSearch'
import IdentifiedTrackersPage from './IdentifiedTrackersPage'
import useIdentifiedTrackersPageQuery from './useIdentifiedTrackersPageQuery'
import useFilters from '../../hooks/useFilters'
import useScanRangeSelect from '../alerts/useScanRangeSelect'
import useCollectionState from '../../hooks/useCollectionState'

const IdentifiedTrackersPageWithState = () => {
    useCloseDrawerOnLeave()
    const [search, handleSearch] = useSearch()
    const {
        collection,
        addToCollection,
        addAllToCollection,
        clearCollection,
        removeFromCollection,
    } = useCollectionState()
    const [isSelecting, onSetSelecting] = useState(false)
    const [selectedAudit, setSelectedAudit] = useState(null)
    const [selectedVendor, setSelectedVendor] = useState(null)
    const [selectedPurpose, setSelectedPurpose] = useState(null)

    const {
        activeFilters,
        filterValues,
        onRemoveFilter,
        onAddFilter,
        onRemoveAllFilters,
    } = useFilters({
        purpose: selectedPurpose,
        audit: selectedAudit,
        vendor: selectedVendor,
    })

    const scanRangeState = useScanRangeSelect()
    const { period, startDate, endDate } = scanRangeState

    const {
        purposes,
        trackers,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useIdentifiedTrackersPageQuery(
        search,
        filterValues.audit?.id,
        selectedPurpose === 'ALL' ? null : filterValues.purpose,
        filterValues.vendor?.id,
        startDate,
        endDate,
        period
    )
    useEvents(['scan:in-progress', 'scan:completed', 'scan:failed'], () =>
        refetch()
    )
    useRefetch(refetch)
    return (
        <IdentifiedTrackersPage
            activeFilters={activeFilters}
            onRemoveFilter={onRemoveFilter}
            onAddFilter={onAddFilter}
            onRemoveAllFilters={onRemoveAllFilters}
            purposes={purposes}
            selectedAudit={selectedAudit}
            selectedVendor={selectedVendor}
            selectedPurpose={selectedPurpose}
            scanRangeState={scanRangeState}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
            trackers={trackers}
            search={search}
            onSearch={handleSearch}
            onChangeAudit={(audit) => setSelectedAudit(audit)}
            onChangeVendor={(vendor) => setSelectedVendor(vendor)}
            onChangePurpose={(id) => setSelectedPurpose(id)}
            onClassifyTrackerCompleted={() => refetch()}
            onUpdateTrackerCompleted={() => refetch()}
            onDeleteTrackerCompleted={() => refetch()}
            isSelecting={isSelecting}
            onStartSelectingTrackers={() => onSetSelecting(true)}
            onStopSelectingTrackers={() => {
                onSetSelecting(false)
                clearCollection()
            }}
            onSelectAllTrackers={(e) => {
                if (e.target.checked) {
                    addAllToCollection(trackers)
                } else {
                    clearCollection()
                }
            }}
            onSelectTracker={(isSelected, tracker) => {
                if (isSelected) {
                    addToCollection(tracker)
                } else {
                    removeFromCollection(tracker)
                }
            }}
            selectedTrackers={collection}
            onSort={(columnId, direction) => {
                refetch({
                    orderBy: {
                        [columnId]: direction,
                    },
                })
            }}
        />
    )
}

export default IdentifiedTrackersPageWithState
