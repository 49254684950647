import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../apollo/fragments/utils'
import useFetchMore from '../hooks/useFetchMore'

const QUERY = gql`
    query vendorsPage(
        $search: String
        $offset: Int
        $filters: VendorsFilter
        $orderBy: VendorOrderByInput
    ) {
        vendors(
            search: $search
            offset: $offset
            filters: $filters
            orderBy: $orderBy
        ) {
            edges {
                cursor
                node {
                    id
                    name
                    belongsToOrganization
                    imageUrl
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

const useVendorSearchSelectQuery = ({ search }) => {
    const variables = { search }
    const {
        data = {},
        error,
        fetchMore,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'vendors'
    )

    return {
        vendors: connectionToCollection(data.vendors),
        error,
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}

export default useVendorSearchSelectQuery
